<script lang="ts" setup>
import UiBottomSheet from "@/ui/UiBottomSheet/UiBottomSheet.vue";
import { computed } from "vue";
import UiButton from "@/ui/UiButton.vue";
import { useDownloadAndroid } from "@/modules/pwa/composables/use-download-android";
import { useDownloadPwa } from "@/modules/pwa/composables/use-download-pwa";

defineOptions({
  name: "AndroidPwaModal",
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "openNotShowModal"]);

const _modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const { goToPwaPage } = useDownloadPwa();

const { downloadPWAAndroid } = useDownloadAndroid();

const downloadToAndroid = () => {
  _modelValue.value = false;
  downloadPWAAndroid();
};

const moreDetailed = () => {
  _modelValue.value = false;

  goToPwaPage();
};

const openNotShowModal = () => {
  localStorage.setItem("isPwaModal", "off");
  _modelValue.value = false;
  emit("openNotShowModal");
};
</script>

<template>
  <UiBottomSheet
    v-model="_modelValue"
    title="Установите приложение RC CITY на ваш смартфон"
    subtitle="И пользуйтесь сервисом с телефона, как обычным мобильным приложением."
  >
    <template #body>
      <div class="android-pwa-modal">
        <img
          src="@/modules/pwa/assets/image/download-pwa-image.png"
          alt="phone"
        />
      </div>
    </template>

    <template #footer>
      <UiButton
        size="l"
        color="secondary"
        label="Больше не показывать"
        full-width
        @click="openNotShowModal"
      />

      <UiButton
        size="l"
        color="grey"
        label="Подробнее о приложении"
        full-width
        @click="moreDetailed"
      />

      <UiButton
        size="l"
        color="accent"
        label="Установить на Android"
        full-width
        @click="downloadToAndroid"
      />
    </template>
  </UiBottomSheet>
</template>

<style lang="scss" scoped>
.android-pwa-modal {
  border-radius: 12px;
  background: var(--color-semantic-background-normal-tertiary);
  display: flex;
  align-items: flex-end;
  justify-content: center;

  > img {
    width: 326px;
  }
}
</style>
