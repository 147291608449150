<script setup lang="ts">
import { computed, PropType } from "vue";

defineOptions({
  name: "UiButton",
});

type ColorType =
  | "accent" // Старое значение (переделанное)
  | "line-gold" // Старое значение
  | "dark" // Старое значение
  | "primary"
  | "grey"
  | "white"
  | "static-dark"
  | "secondary"
  | "secondary-grey";
type SizeType = "l" | "m" | "m2" | "s";

const props = defineProps({
  color: {
    type: String as PropType<ColorType>,
    default: "accent",
  },

  label: {
    type: String,
    default: "",
  },

  type: {
    type: String,
    default: "button",
  },

  size: {
    type: String as PropType<SizeType>,
    default: "m",
  },

  loading: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  fitContent: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: undefined,
  },

  to: {
    type: Object,
    default: null,
  },

  target: {
    type: String,
    default: "",
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },
});

const currentTag = computed(() => {
  if (props.href && !props.to) {
    return "a";
  } else if (!props.href && props.to) {
    return "router-link";
  } else {
    return "button";
  }
});

const filteredProps = computed(() => {
  return {
    ...(currentTag.value === "button" && {
      type: props.type,
      disabled: props.disabled || props.loading,
    }),

    ...(currentTag.value !== "button" && {
      target: props.target,
    }),

    ...(currentTag.value === "router-link" && {
      to: props.to,
    }),

    ...(currentTag.value === "a" && {
      href: props.href,
    }),
  };
});
</script>

<template>
  <component
    :is="currentTag"
    class="ui-button"
    :class="[
      `ui-button_color_${color}`,
      `ui-button_size_${size}`,
      {
        'ui-button_fit-content': fitContent,
        'ui-button_loading': loading,
        'ui-button_full-width': fullWidth,
      },
    ]"
    v-bind="filteredProps"
  >
    <slot></slot>
    <span v-if="label" class="ui-button__label">{{ label }}</span>
    <span v-if="loading" class="ui-button__icon-loading icon-loader"></span>
  </component>
</template>

<style lang="scss">
.ui-button {
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  border: none;
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: fit-content;
  user-select: none;
  transition: all 0.15s ease-out;
  flex-shrink: 0;
  gap: 4px;

  &_loading {
    .ui-button__label {
      opacity: 0;
    }

    &:active {
      box-shadow: none;
    }

    > span.icon {
      opacity: 0;
    }
  }

  &__icon-loading {
    background: var(--color-semantic-content-other-disable, #a8aaad);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_color {
    &_accent {
      background: var(--color-semantic-content-normal-brand, #ffcc49);
      color: var(--color-semantic-content-normal-static-dark, #1e1f21);

      // // &:focus,
      &:hover {
        background: var(--color-semantic-background-hover-brand, #f7bb23);
      }

      &:active {
        background: var(--color-semantic-background-active-brand, #f2ad00);
      }
    }

    // Нужно будет удалить
    &_line-gold {
      border: 1px solid var(--gold-main, #ffda7a);
      background: inherit;
      color: var(--gold-main, #ffda7a);

      > span.icon {
        background: var(--gold-main, #ffda7a);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    // Нужно будет удалить
    &_dark {
      border: 1px solid var(--color-semantic-border-normal-soft);
      background: var(--color-semantic-background-normal-secondary);
      color: var(--color-semantic-content-normal-contrast-primary);

      > span.icon {
        background: var(--text-dark-primary, #fff);
      }

      @media (hover: hover) {
        &:hover {
          border: 1px solid var(--text-dark-sixth, #bdbdbd);
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    &_primary {
      background: var(--color-semantic-background-normal-contrast, #1e1f21);
      color: var(--color-semantic-content-normal-contrast-primary, #fff);

      // &:focus,
      &:hover {
        background: var(--color-semantic-background-hover-contrast, #313336);
      }

      &:active {
        background: var(--color-semantic-background-active-contrast, #434547);
      }
    }

    &_grey {
      background: var(--color-semantic-background-normal-secondary, #f7f7f7);
      color: var(--color-semantic-content-normal-primary, #1e1f21);

      // &:focus,
      &:hover {
        background: var(--color-semantic-background-hover-secondary, #ebeced);
      }

      &:active {
        background: var(--color-semantic-background-active-secondary);
      }
    }

    &_wihte {
      background: var(--background-light-primary);
      color: var(--consistent-dark-primary);

      &:hover {
        background: var(--background-light-primary);
      }

      &:active {
        background: var(--background-light-fourth);
      }
    }

    &_secondary {
      background: transparent;
      border: 1px solid var(--color-semantic-border-normal-soft);
      color: var(--color-semantic-content-normal-primary);

      // &:focus,
      &:hover {
        border: 1px solid var(--color-semantic-border-hover-soft);
      }

      &:active {
        border: 1px solid var(--color-semantic-border-active-soft);
      }
    }

    &_secondary-grey {
      background: transparent;
      border: 1px solid var(--color-semantic-border-normal-soft);
      background: var(--color-semantic-background-normal-secondary);
      color: var(--color-semantic-content-normal-primary);

      &:hover {
        border: 1px solid var(--color-semantic-border-hover-soft);
        background: var(--color-semantic-background-hover-secondary);
      }

      &:active {
        border: 1px solid var(--color-semantic-border-active-soft);
        background: var(--color-semantic-background-active-secondary);
      }
    }

    &_static-dark {
      background: var(--color-semantic-background-normal-static-dark);
      color: var(--color-semantic-content-normal-static-light);
    }
  }

  &_size {
    &_l {
      @include body-1;
      height: 48px;
      flex: 0 0 48px;
      padding: 12px 20px;

      @media screen and (max-width: 1279px) {
        height: 44px;
        flex: 0 0 44px;
        padding: 10px 16px;
      }

      > span.icon {
        width: 16px;
        height: 16px;
      }

      .ui-button__icon-loading {
        width: 16px;
        height: 16px;
      }
    }

    &_m {
      @include body-2;
      flex: 0 0 36px;
      min-height: 36px;
      padding: 8px 24px;

      > span.icon {
        width: 16px;
        height: 16px;
      }

      .ui-button__icon-loading {
        width: 16px;
        height: 16px;
      }
    }

    &_m2 {
      @include body-3;
      flex: 0 0 36px;
      min-height: 36px;
      padding: 8px 24px;

      > span.icon {
        width: 16px;
        height: 16px;
      }

      .ui-button__icon-loading {
        width: 16px;
        height: 16px;
      }
    }

    &_s {
      @include body-2;
      flex: 0 0 28px;
      min-height: 28px;
      padding: 6px 12px;

      > span.icon {
        width: 16px;
        height: 16px;
      }

      .ui-button__icon-loading {
        width: 16px;
        height: 16px;
      }
    }
  }

  &_full-width {
    width: 100%;
    min-width: 100%;
  }

  &_fit-content {
    word-wrap: break-word;
  }

  &:disabled {
    background: var(--color-semantic-background-other-disable);
    color: var(--color-semantic-content-other-disable);
    cursor: not-allowed !important;
  }
}
</style>
