<script>
import { mapActions, mapMutations, mapState } from "vuex";
import LoaderInline from "@/components/LoaderInline";
import ButtonIcon from "@/components/common/ButtonIcon";
import SectionUpdateMessageModal from "@/components/layouts/SectionUpdateMessageModal";
import MainSidebar from "@/modules/sidebar/MainSidebar.vue";
import MainNotify from "@/components/helpers/MainNotify.vue";
import UiButton from "@/ui/UiButton.vue";
import AndroidPwaModal from "@/modules/pwa/components/AndroidPwaModal.vue";
import NotShowAnymoreModal from "@/modules/pwa/components/NotShowAnymoreModal.vue";
import { usePlatform } from "@/modules/pwa/composables/use-platform";
import IosPwaModal from "@/modules/pwa/components/IosPwaModal.vue";

export default {
  name: "ProfileLayout",

  components: {
    IosPwaModal,
    NotShowAnymoreModal,
    AndroidPwaModal,
    UiButton,
    MainNotify,
    ButtonIcon,
    MainSidebar,
    LoaderInline,
    SectionUpdateMessageModal,
  },

  props: {
    isShowOption: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const { isAndroid, isIOS, isPWADownload } = usePlatform();

    return {
      isAndroid,
      isIOS,
      isPWADownload,
    };
  },

  data() {
    return {
      loading: true,
      entity: [],
      myEntity: [],
      crumbs: [],
      permissionBalance: false,
      dataForUpdateMessage: null,
      isModalOpen: false,
      isOpenNotify: false,
      notify: null,
      isOPenSuccessNotify: false,
      isOpenToast: false,
      oldSubtypes: [
        "tt-update",
        "clients-update",
        "product-release",
        "review-update-release",
      ],

      isOpenAndroidPwaModal: false,
      isOpenIosPwaModal: false,
      isOpenNotShowPwaModal: false,
    };
  },

  watch: {
    windowInnerWidth: {
      handler(newValue) {
        if (newValue < 1280) {
          this.SET_IS_SHOW_SIDEBAR(false);
        } else {
          this.SET_IS_SHOW_SIDEBAR(true);
        }
        this.SET_IS_COLLAPSED_SIDEBAR(false);
      },
    },

    notification: {
      handler(newValue) {
        this.isOpenToast = !!newValue;
      },
    },
  },

  computed: {
    ...mapState({
      entities: (state) => state.entity.entities,
      profile: (state) => state.user.profile,
      windowInnerWidth: (state) => state.main.windowInnerWidth,
      isShowSidebar: (state) => state.main.isShowSidebar,
      isCollapsedSidebar: (state) => state.main.isCollapsedSidebar,
      notification: (state) => state.notification,
    }),

    isShowNotify() {
      return this.isOpenNotify;
    },

    sideBarTransitionName() {
      if (this.windowInnerWidth < 1280) {
        return "slide-left";
      }
      return "";
    },
  },

  methods: {
    ...mapActions({
      logout: "user/logout",
      logoutOwner: "user/logoutOwner",
      getEntity: "entity/getEntities",
      getProfile: "user/getProfileInformation",
      internalNotificationsAccount: "auth/internalNotificationsAccount",
      createNotification: "createNotification",
    }),

    ...mapMutations({
      SET_INNER_WIDTH: "main/SET_INNER_WIDTH",
      SET_IS_SHOW_SIDEBAR: "main/SET_IS_SHOW_SIDEBAR",
      SET_IS_COLLAPSED_SIDEBAR: "main/SET_IS_COLLAPSED_SIDEBAR",
      SET_NOTIFICATION: "SET_NOTIFICATION",
    }),

    checkOpenDownloadPwaModal() {
      const isPwaModal = localStorage.getItem("isPwaModal");
      if (isPwaModal === "off") {
        return;
      }

      if (!this.isPWADownload && this.isAndroid) {
        this.isOpenAndroidPwaModal = true;
      } else if (!this.isPWADownload && this.isIOS) {
        this.isOpenIosPwaModal = true;
      }
    },

    onResize() {
      this.SET_INNER_WIDTH(window.innerWidth);
    },

    logoutAll() {
      if (this.profile.is_owner) {
        this.logoutOwner().finally(() => {
          this.$router.push({ name: "Lending" });
        });
      } else {
        this.logout().finally(() => {
          this.$router.push({ name: "Lending" });
        });
      }

      localStorage.removeItem("creationAllowed");
    },

    clickOnBalance() {
      this.$router.push({ name: "Balance" });
    },

    openMobileSidebar() {
      this.SET_IS_SHOW_SIDEBAR(true);
      this.SET_IS_COLLAPSED_SIDEBAR(false);
    },

    async loadEntities() {
      await this.getEntity();

      if (this.entities.length === 0) {
        localStorage.setItem("emailCode", "done");
        this.$router.push({ name: "Registration" });
      } else {
        this.myEntity = this.entities.map((entity) => ({
          label: entity.name,
          value: entity.name,
          id: entity.id,
          balance: entity.balance,
        }));
      }
    },

    async loadProfile() {
      await this.getProfile();

      this.profile.permissions.forEach((permission) => {
        if (permission === "balance") {
          this.permissionBalance = true;
        }
      });

      if (this.profile.has_debt) {
        setTimeout(() => {
          this.isOpenNotify = true;

          this.notify = {
            type: "debt",
            status: "error",
            title: this.$t("debt.title"),
            text: this.$t("debt.text"),
            link: {
              to: { name: "Balance" },
              label: this.$t("debt.link"),
            },
          };
        }, 1000);
      }
    },
  },

  beforeMount() {
    localStorage.setItem("selected-language", "ru");
    this.$i18n.locale = "ru";

    localStorage.removeItem("entityRegistered");
    localStorage.removeItem("emailCode");
    localStorage.removeItem("email");
  },

  mounted() {
    this.loading = true;
    this.internalNotificationsAccount().then((response) => {
      if (!response.data.data[0]) {
        return;
      }
      // TODO: refactor create notify object
      if (!this.oldSubtypes.includes(response.data.data[0].subtype)) {
        this.notify = {
          type: "another",
          id: response.data.data[0].id,
          status: response.data.data[0].additional_data.status,
          title: response.data.data[0].header,
          text: response.data.data[0].message,
          link: {
            to: response.data.data[0].additional_data.url,
            label: response.data.data[0].additional_data.label,
          },
        };

        setTimeout(() => {
          this.isOpenNotify = true;
        }, 2000);
      } else {
        this.dataForUpdateMessage = response.data.data[0];
        this.isModalOpen = true;
      }
    });

    this.$nextTick(() => {
      this.windowInnerWidth < 1279
        ? this.SET_IS_SHOW_SIDEBAR(false)
        : this.SET_IS_SHOW_SIDEBAR(true);
      window.addEventListener("resize", this.onResize);
    });

    const showBalanceBTN = () => {
      if (this.profile?.permissions) {
        for (const i of this.profile.permissions) {
          if (i === "balance") {
            this.permissionBalance = true;
          }
        }

        if (!this.profile.has_debt) {
          return;
        }

        setTimeout(() => {
          this.isOpenNotify = true;

          this.notify = {
            type: "debt",
            status: "error",
            title: this.$t("debt.title"),
            text: this.$t("debt.text"),
            link: {
              to: { name: "Balance" },
              label: this.$t("debt.link"),
            },
          };
        }, 1000);
      }
    };

    const startProfileWork = async () => {
      if (!this.profile?.permissions) {
        await this.getProfile();
        showBalanceBTN();
      } else {
        showBalanceBTN();
      }
    };

    Promise.all([
      startProfileWork(),

      this.getEntity().then(() => {
        if (this.entities.length === 0) {
          localStorage.setItem("emailCode", "done");
          this.$router.push({ name: "Registration" });
        } else {
          for (const i in this.entities) {
            this.myEntity.push({
              label: this.entities[i].name,
              value: this.entities[i].name,
              id: this.entities[i].id,
              balance: this.entities[i].balance,
            });
          }
        }
      }),
    ]).finally(() => {
      this.loading = false;
    });

    this.checkOpenDownloadPwaModal();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<template>
  <div class="profile-layout">
    <LoaderInline :loading="loading" />

    <div
      v-if="!loading"
      :class="{
        'layout-container_full-width': !isShowSidebar,
        'layout-container_collapsed-sidebar': isCollapsedSidebar,
      }"
      class="profile-layout__layout-container layout-container"
    >
      <Transition :name="sideBarTransitionName">
        <MainSidebar v-show="isShowSidebar" @logoutAll="logoutAll" />
      </Transition>

      <div
        ref="pageContainer"
        class="layout-container__page-container page-container"
      >
        <div class="page-container__navigation">
          <ButtonIcon
            class="page-container__burger-menu"
            @click="openMobileSidebar()"
          >
            <span class="icon-burger" />
          </ButtonIcon>

          <div class="page-container__buttons">
            <UiButton
              v-if="permissionBalance"
              class="balance"
              :label="$t('sys.balance')"
              color="grey"
              :padding="[7, 24]"
              @click="clickOnBalance"
            />
          </div>
        </div>

        <MainNotify
          v-if="notify"
          :is-show="isShowNotify"
          :notify="notify"
          @close="isOpenNotify = false"
        />

        <RouterView />
      </div>

      <SectionUpdateMessageModal
        v-model="isModalOpen"
        :modal-data="dataForUpdateMessage"
      />
    </div>

    <AndroidPwaModal
      v-model="isOpenAndroidPwaModal"
      @openNotShowModal="isOpenNotShowPwaModal = true"
    />

    <IosPwaModal
      v-model="isOpenIosPwaModal"
      @openNotShowModal="isOpenNotShowPwaModal = true"
    />

    <NotShowAnymoreModal v-model="isOpenNotShowPwaModal" />
  </div>
</template>

<style lang="scss" scoped>
.profile-layout {
  height: 100dvh;
  max-height: 100dvh;

  .layout-container {
    height: 100%;
    display: grid;
    grid-template-columns: 380px calc(100% - 380px);
    color: $new-light-primary;
    z-index: 1;
    position: relative;
    @include transition;

    &_full-width {
      grid-template-columns: 100% !important;
    }

    &_collapsed-sidebar {
      grid-template-columns: 60px calc(100% - 60px);
    }

    .page-container {
      width: 100%;
      display: grid;
      grid-template-rows: 68px calc(100% - 68px);
      height: -webkit-fill-available;
      max-height: 100vh;
      position: relative;
      z-index: 1;
      @include transition;

      &__navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 32px;
        border-bottom: 1px solid
          var(--color-semantic-border-normal-soft, #ebeced);
        background: var(--color-semantic-background-normal-bg-primary, #fff);
        @include transition;
      }

      .crumbs {
        display: flex;
        flex-direction: row;
        gap: 4px;

        &__block {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
        }

        &__icon {
          width: 24px;
          height: 24px;
          background: $new-light-sixth;
        }

        &_not-active {
          color: $new-light-sixth;
        }

        :not(:last-child) {
          :after {
            content: " /";
          }
        }
      }

      &__burger-menu {
        display: none;
        padding: 0;
      }

      .icon-burger {
        width: 32px;
        height: 32px;
        background: var(--color-semantic-content-normal-primary);
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .balance {
          @include body-1-bold;
        }

        .filter {
          display: none;
          padding: 4px;
        }

        .icon-filter {
          width: 24px;
          height: 24px;
          background: $new-light-primary;
        }
      }
    }
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
  &::after {
    opacity: 0;
  }
}

@media (max-width: 1535px) {
  .profile-layout {
    .layout-container {
      grid-template-columns: 300px calc(100% - 300px);

      &_collapsed-sidebar {
        grid-template-columns: 60px calc(100% - 60px);
      }

      .page-container {
        &__navigation {
          padding: 18px 24px;
        }
      }
    }
  }
}

@media (max-width: 1535px) {
  .profile-layout {
    .page-container {
      max-height: -webkit-fill-available;
    }
  }
}

@media (max-width: 1279px) {
  .profile-layout {
    .layout-container {
      grid-template-columns: 320px calc(100% - 320px);

      &_collapsed-sidebar {
        grid-template-columns: 60px calc(100% - 60px);
      }
    }
  }
}

@media (max-width: 1279px) {
  .profile-layout {
    height: 100dvh;

    .layout-container {
      grid-template-columns: 1fr;
      position: relative;

      .page-container {
        height: 100dvh;

        &__navigation {
          justify-content: space-between;
        }

        &__burger-menu {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .profile-layout {
    height: 100dvh;

    .layout-container {
      grid-template-columns: 1fr;
      position: relative;

      .page-container {
        height: 100dvh;

        &__navigation {
          justify-content: space-between;
        }

        &__burger-menu {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 565px) {
  .filter {
    display: block !important;
  }
}

@media (max-width: 500px) {
  .profile-layout {
    .layout-container {
      .page-container__navigation {
        padding: 18px 16px;
      }
    }
  }
}
</style>
