<script lang="ts" setup>
import { computed } from "vue";
import UiButton from "@/ui/UiButton.vue";
import UiBottomSheet from "@/ui/UiBottomSheet/UiBottomSheet.vue";
import { useDownloadPwa } from "@/modules/pwa/composables/use-download-pwa";

defineOptions({
  name: "IosPwaModal",
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "openNotShowModal"]);

const _modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const { goToPwaPage } = useDownloadPwa();

const openNotShowModal = () => {
  localStorage.setItem("isPwaModal", "off");
  _modelValue.value = false;
  emit("openNotShowModal");
};

const moreDetailed = () => {
  _modelValue.value = false;
  goToPwaPage();
};
</script>

<template>
  <UiBottomSheet
    v-model="_modelValue"
    title="Установите приложение RC CITY на ваш смартфон"
    subtitle="И пользуйтесь сервисом с телефона, как обычным мобильным приложением."
  >
    <template #body>
      <div class="ios-pwa-modal">
        <div class="ios-pwa-modal__image-container">
          <img
            src="@/modules/pwa/assets/image/pwa-ios-modal.png"
            alt="pwa-ios"
          />
        </div>

        <ol>
          <li>
            Откройте сайт
            <a href="https://rc.city/" target="_blank">rc.city</a> c телефона.
          </li>

          <li>
            В меню браузера нажмите <span class="icon-link-mobile" /> и выберите
            «На экран Домой».
          </li>
          <li>
            Иконка приложения RC CITY появится на экране вашего смартфона.
          </li>
        </ol>
      </div>
    </template>

    <template #footer>
      <UiButton
        size="l"
        color="secondary"
        label="Больше не показывать"
        full-width
        @click="openNotShowModal"
      />

      <UiButton
        size="l"
        color="accent"
        label="Подробнее о приложении"
        full-width
        @click="moreDetailed"
      />
    </template>
  </UiBottomSheet>
</template>

<style lang="scss" scoped>
.ios-pwa-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__image-container {
    border-radius: 12px;
    background: var(--color-semantic-background-normal-tertiary);
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 288px;
      height: 200px;
    }
  }

  > ol {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 30px;

    > li {
      @include body-1;
      color: var(--color-semantic-content-normal-secondary);

      > a {
        color: var(--color-semantic-content-normal-link);
      }

      .icon-link-mobile {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;
        background-color: var(--color-semantic-content-normal-tertiary);
      }
    }
  }
}
</style>
